import axios from "axios";
import React from "react";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "./style/login.css";
import swal from "sweetalert";
import { BiHide, BiShow } from "react-icons/bi";
import md5 from "md5";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { data } from "jquery";

const LoginBordMember = () => {
  const [showOtp, setShowOtp] = useState(false)
  const [number, setNumber] = useState(false)

  const [otps, setOtps] = useState()
  const [userFormData, setUserFormData] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [key, setKey] = useState("");
  const [criptKey, setCriptKey] = useState("");
  const [criptPass, setCriptPass] = useState("");
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");
  const formattedDate = `${year}${month}${day}`;

  const handelUserFormData = (e) => {
    const { name, value } = e.target;
    setUserFormData({ ...userFormData, [name]: value });
  };

  useEffect(() => {
    setKey(md5(`tgb_${formattedDate}_${email}`));
  }, [email, formattedDate]);

  useState(() => {
    setCriptKey(md5(key));
  }, [key]);

  useEffect(() => {
    const logData = { ...userFormData };
    logData.Key = key;
    logData.Pass = password;
    logData.Email = email;

    setUserFormData(logData);
  }, [key, email, password]);

  const loginUser = () => {
    axios
      .post("https://www.tripuragraminbank.org/api/login_boardmember.php", userFormData)
      .then((res) => {
        if (res.data?.responseStatus) {
          swal(res.data?.responseMessage);
          setShowOtp(true)
          setNumber(res.data?.responsePhone)
        }
      })
      .catch((err) => {
        if(err?.response?.data?.responseMessage){
        swal(err?.response?.data?.responseMessage); 
        }else{
          swal('You need to provide valid credentials');
        }
        

      });
  };

  const otploginUser = () => {
    if(otps != undefined && otps){ 
      axios
        .post("https://www.tripuragraminbank.org/api/verify_login_otp.php", {Phone : number, OTP : (md5(otps))})
        .then((res) => {
          if (res.status === 200) { 
            window.sessionStorage.setItem("Access_token", res.data.responseToken);
            window.sessionStorage.setItem("roleType", res.data.responseRoleType);
            swal(res.data?.responseMessage);  
            setShowOtp(false)
            navigate("/agenda-cirular"); 
          }
        })
        .catch((err) => { 
          swal('OTP code did not match!');  
        });
    }else{
      swal('You need fill OTP');
    }
  };

  return (
    <div class="login">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Board Member Login User</title>
        <meta name="description" content="Board Member Login" />
      </Helmet>
      {
        showOtp &&
      <div style={{ position: 'fixed', top: '0', left: '0', zIndex: '20', width: '100%', height: '100%', background: "rgba(0,0,0,0.5)" }} class='d-flex align-items-center justify-content-center'>
        <div class='bg-white col-md-6 col-11 bg-success'>
          <div class='col-11 m-auto my-4 text-start'>
            <div>
              <label htmlFor="otp">OTP</label><br />
              <input type="text" class='form-control' placeholder="OTP" id="otp" onChange={(e) => setOtps(e.target.value)} />
            </div>
            <div class='mt-3 d-flex gap-3'>
              <button class='btn btn-success' onClick={otploginUser}>Submit</button>
              <button class='btn  btn-outline-success' onClick={loginUser}>Resend</button>
              <button class='btn  btn-outline-danger' onClick={()=>setShowOtp(false)}>Close</button>

            </div>
          </div>

        </div>
      </div>
      }
      <div class="login1 text-start p-3">
        <p class="fs-1 fw-bold">Board Member Log-in</p>
        <input
          class="form-control p-2 "
          type="text"
          placeholder="User Id"
          name="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <div class="input-group">
          <input
            class="form-control p-2"
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            name="Pass"
            onChange={(e) => setPassword(md5(e.target.value))}
          />
          {showPassword ? (
            <span
              class="input-group-text"
              onClick={() => setShowPassword(false)}
            >
              <BiHide />
            </span>
          ) : (
            <span
              class="input-group-text"
              onClick={() => setShowPassword(true)}
            >
              <BiShow />
            </span>
          )}
        </div>
        <br />
        <button
          class="btn btn-success p-2 px-3"
          id="success"
          onClick={loginUser}
        >
          {" "}
          Submit
        </button>
        <button
          class="btn btn-success p-2 px-3 mx-4"
          onClick={() => navigate("/forget-pasword")}
        >
          {" "}
          Forget Password
        </button>
      </div>
    </div>
  );
};

export default LoginBordMember;
